<template>
  <div class="wrapper">
    <section class="landing-header" v-if="site">
      <nav>
        <a
            href="https://www.bcocongresos.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="logo"
        >
          <img
              src="@/assets/Bco-Congresos_firma.png"
              alt="bco_congresos_logo"
          />
        </a>
      </nav>
      <transition name="grow-left-right" appear>
        <section class="hero">
          <transition name="fade-in-bottom-up" appear>
            <span class="logo">
              <img
                  src="@/assets/Bco-Congresos_blanco.png"
                  alt="bco_congresos_logo"
                  style="height: 100%"
              />
              </span>
          </transition>

          <div>
            <h3>{{ $t('officialHousing') }}</h3>
            <div class="congress-name">
              <h2 v-html="site.headline"> </h2>
            </div>
            <div class="congress-date">
              <h3>{{ site.date_representation }}</h3>
              <h3>{{ site.congress_city }}, {{ site.state }}</h3>
            </div>
          </div>
        </section>
      </transition>
      <section class="congress-info">
        <div class="logo-container">
          <img :src="site.logo" class="congress-logo" alt="Congress logo"/>
        </div>
        <transition name="title" appear>
          <h1 id="congress-claim" v-html="site.claim">
          </h1>
        </transition>
        <h3 v-html="site.claim_under">
        </h3>

        <transition name="card-right-left-fade-in" appear>
          <div class="card-container">
            <div class="card">
              <router-link
                  :to="`/list?walking=true`"
                  class="card-link"
              ></router-link>
              <img
                  class="background"
                  src="../../assets/card_walking_distance.png"
                  alt
              />
              <img
                  class="icon"
                  src="../../assets/ic_home_walking_white.svg"
                  alt
              />
              <p>{{ $t('nearVenue') }}</p>
              <p class="offers-count">
                {{ $t('see') }} {{ getHotelsCount("near_to_congress") }} {{ $t('options') }}
              </p>
            </div>
            <div class="card">
              <router-link
                  :to="`/list?ecoFriendly=true`"
                  class="card-link"
              ></router-link>
              <img
                  class="background"
                  src="../../assets/card_headquarters.png"
                  alt
              />
              <img
                  class="icon"
                  src="../../assets/ic_home_headquarters.svg"
                  alt
              />
              <p>{{ $t('ecoFriendly') }}</p>
              <p class="offers-count">
                {{ $t('see') }} {{ getHotelsCount("eco_friendly") }} {{ $t('options') }}
              </p>
            </div>
            <div class="card">
              <router-link
                  :to="`/list?city=true`"
                  class="card-link"
              ></router-link>
              <img
                  class="background"
                  src="../../assets/card_around_city.png"
                  alt
              />
              <img
                  class="icon"
                  src="../../assets/ic_home_city_center_white.svg"
                  alt
              />
              <p>{{ $t('cityCenter') }}</p>
              <p class="offers-count">
                {{ $t('see') }} {{ getHotelsCount("near_to_center") }} {{ $t('options') }}
              </p>
            </div>
          </div>
        </transition>
        <transition name="double-button" appear>
          <router-link class="btn btn-primary" tag="a" :to="`/list`"
          >{{ $t('searchAll') }}
          </router-link
          >
        </transition>
        <transition name="double-button" appear>
          <div class="scroll-down-container">
            <p class="scroll-down">{{ $t('scrollDown') }}</p>
            <img src="@/assets/ic_scroll_down.svg" alt/>
          </div>
        </transition>
      </section>
    </section>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "LandingHeader",
  computed: {
    ...mapGetters(['site', 'hotels']),
  },
  methods: {
    getHotelsCount(category) {
      if (!category) {
        return this.hotels.length;
      }
      const hotels = this.hotels.filter((hotel) => {
        return hotel[category];
      });
      return hotels.length;
    },
  },
};
</script>


<style lang="scss">
.fp-tableCell {
  vertical-align: middle;
  display: table-cell;
}
.fp-table{
  table-layout: fixed;
  display: table;
  width: 100%;
}
.footer-container.fp-section, .footer-container.fp-slide {
  height: auto!important;
}
@media (min-height: 991px) {
  .footer-container.fp-section, .footer-container.fp-slide {
    height: inherit!important;
  }
  .footer-container .fp-tableCell {
    vertical-align: middle;
    height: 100vh !important;
    margin-top: 10px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/color-mixin.scss";

.landing-header {
  margin: 0;
  color: white;

  nav {
    height: 60px;
    text-align: left;
    margin: 0 10px;

    img {
      height: 100%;
    }
  }

  section.hero {
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .4))), var(--hero-image);
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, .4)), var(--hero-image);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    min-height: 290px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      display: none;
    }

    h3 {
      margin: 0;
    }

    .congress-name {
      h2:first-child {
        position: relative;

        sup {
          font-size: 16px;
          vertical-align: text-top;
          position: absolute;
          bottom: 15px;
        }
      }

      h2 {
        font-size: 60px;
        line-height: 55px;
        margin: 0;
      }
    }

    .congress-date {
      h3 {
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
      }
    }
  }

  section.congress-info {
    margin: 0 0 40px 0;
    color: $secondary-black;

    div.logo-container {
      border-bottom: 1px solid #eeeeee;
      margin: 0 10px 30px 10px;

      img.congress-logo {
        padding: 35px 0;
        max-width: 420px;
        max-height: 120px;
      }
    }

    h1#congress-claim {
      font-size: 25px;
      line-height: 36px;
      margin: 0 10px 35px 0;

      ::v-deep(span) {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: var(--primary-color);
        }
      }
    }

    h3 {
      margin: 0 10px 40px 0;
      font-size: 15px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;

      ::v-deep(span) {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          left: 0;
          height: 3px;
          width: 100%;
          background-color: var(--primary-color);
        }
      }
    }

    .card-container {
      margin-bottom: 35px;
      display: flex;
      flex-wrap: nowrap;
      margin-left: 20px;

      .card {
        flex: 0 0 auto;
        color: white;
        height: 225px;
        width: 150px;
        border-radius: 5px;
        position: relative;
        margin-right: 10px;
        overflow: hidden;
        transition: box-shadow 0.5s linear;
        font-size: 16px;

        &:hover {
          box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
        }

        .card-link {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;

          &:hover + .background {
            transform: scale(1.1);
          }
        }

        .icon {
          position: absolute;
          top: 10px;
          left: 10px;
          right: 10px;
        }

        .background {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 5px;
          //filter: brightness(50%);
          transition: transform 0.5s linear;
          height: 100%;
        }

        p {
          position: absolute;
          top: 80px;
          left: 10px;
          right: 10px;
          text-align: left;
          font-weight: 700;
          pointer-events: none;
        }

        .offers-count {
          margin: 0;
          top: initial;
          position: absolute;
          bottom: 10px;
          left: 10px;
          right: 10px;
          text-align: left;
          font-weight: 600;
        }
      }
    }

    .btn {
      display: block;
      text-decoration: none;
      font-weight: bolder;
      margin: 0 30px;
      color: white;
      border-radius: 5px;
      padding: 15px 20px;
      line-height: 26px;
      text-transform: uppercase;
      font-size: 16px;
      height: auto;
      transition: all 0.1s ease;
      margin-bottom: 30px;
    }

    .btn-primary {
      background-color: var(--primary-color);

      &:hover {
        background-color: var(--hover-color);
      }
    }

    .btn-secondary {
      color: var(--primary-color);
      border: 2px solid var(--primary-color);

      &:hover {
        color: var(--hover-color);
        border: 3px solid var(--hover-color);
        margin: -1px;
      }
    }

    .scroll-down-container {
      p {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  .wrapper {
    height: 100%;
  }

  .landing-header {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    grid-template-areas: "hero congressInfo";
    margin: 0 20px;
    height: 96%;

    nav {
      display: none;
    }

    section.hero {
      grid-area: hero;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 15% 85%;
      grid-template-areas:
        "bcoLogo"
        "congressData";

      a {
        grid-area: bcoLogo;
        display: initial;
        height: 100px;
        align-self: center;

        img {
          height: 100%;
        }
      }

      > div {
        grid-area: congressData;
        padding-top: 65px;

        > h3:first-child {
          margin-bottom: 45px;
        }
      }

      h3 {
        font-size: 1.17em;
        align-self: end;
      }

      .congress-name {
        align-self: end;

        h2 {
          font-size: calc(4em + 1vw);
          line-height: 1.1;
        }

        h2:first-child {
          sup {
            font-size: 20px;
            bottom: 38px;
          }
        }
      }

      div.congress-date {
        h3 {
          font-size: 20px;
          margin-top: 16px;
        }
      }
    }

    section.congress-info {
      grid-area: congressInfo;
      text-align: left;
      padding: 10px 60px 20px 80px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div.logo-container {
        border: none;
        margin: 0;

        img.congress-logo {
          padding-top: 5px;
          padding-bottom: 0;
        }
      }

      h1#congress-claim {
        font-size: 2.6rem;
        line-height: 55px;
        margin-bottom: 0;

        span {
          &::after {
            height: 5px;
          }
        }
      }

      h3 {
        font-size: 1.4rem;
        max-width: 450px;
        margin-top: 0;

        span {
          &::after {
            height: 5px;
            bottom: -4px;
          }
        }
      }

      .card-container {
        margin-left: 0;

        .card {
          margin-right: 25px;
          padding: 10px 10px;
        }
      }

      .btn-primary {
        width: 240px;
        margin-left: 0;
      }

      .scroll-down-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        p {
          margin-bottom: 0;
          align-self: center;
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-height: 900px) {
  .landing-header {
    section.congress-info {
      h1#congress-claim {
        font-size: 30px;
        line-height: 45px;
        margin-bottom: 0;
      }

      h3 {
        font-size: 20px;
        margin-bottom: 0;
      }

      .card-container {
        margin-bottom: 0;

        .card {
          height: 200px;
          width: 125px;
        }
      }

      a.btn.btn-primary {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .card-container {
    overflow-x: auto;
  }
}

@media (min-width: 768px) {

  .grow-left-right-enter-active {
    animation: grow-left-right-in 1.5s;
  }

  @keyframes grow-left-right-in {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .fade-in-bottom-up-enter-active {
    opacity: 0;
    animation: fade-in-bottom-up-in 1s forwards;
    animation-delay: 0.4s;
  }

  @keyframes fade-in-bottom-up-in {
    from {
      transform: translateY(300px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .card-right-left-fade-in-enter-active {
    opacity: 0;
    animation: card-right-left-fade 0.8s forwards;
    animation-delay: 0.4s;
  }

  @keyframes card-right-left-fade {
    from {
      transform: translateX(500px);
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .double-button-enter-active {
    opacity: 0;
    animation: double-button-in 0.8s ease-in forwards;
    animation-delay: 0.4s;
  }

  @keyframes double-button-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .title-enter-active {
    opacity: 0;
    animation: title-in 0.8s ease-in forwards;
    animation-delay: 0.4s;
  }

  @keyframes title-in {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .right-section span::after {
    width: 0;
    animation: hotel-underline-in 1s forwards;
    animation-delay: 1s;
  }

  @keyframes hotel-underline-in {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 930px) {
    .card-container {
      width:100%;
      overflow:hidden;
      overflow-x:scroll;
      white-space:nowrap;
      padding-bottom:10px;
    }
    .card-container::-webkit-scrollbar-thumb {
      background:var(--primary-color);
    }
    .card-container::-webkit-scrollbar {
      width:5px;
      height:8px;
      background-color: #ccc;
    }
    .card {
      display: inline-block;
    }
}

@media (max-width: 1024px) {
  .landing-header section.congress-info {
    padding:10px 0 10px 40px;
  }
  .landing-header section.congress-info .card-container .card {
    margin-right:10px;
  }
}

@media (max-width: 768px) {
  section.info-housing {
    .vueperslides__parallax-wrapper {
      padding-top:0 !important;
    }
    .vueperslides__bullets {
      bottom:75px;
    }
  }
}

@media (max-width: 600px) {
  .landing-header section.congress-info {
    padding:0 20px;
  }
  .card-container {
    width:100%;
    overflow:hidden;
    overflow-x:scroll;
    white-space:nowrap;
    padding-bottom:10px;
    margin:0;
    margin-left:0 !important;
  }
  .card-container::-webkit-scrollbar-thumb {
    background:var(--primary-color);
  }
  .card-container::-webkit-scrollbar {
    width:5px;
    height:8px;
    background-color: #ccc;
  }
  .card {
    display: inline-block;
    margin-right:30px !important;
  }
}
@media screen and (max-height: 760px) {
  .landing-header {zoom:0.9;}
  .landing-header section.hero[data-v-21be646a] {
    grid-template-rows: 15% 75% !important;
  }
  .landing-header section.hero .congress-name h2 h5 {
      margin:0 !important;
  }
  .landing-header section.hero > div {
    padding-top:40px !important;
  }
}
@media screen and (max-height: 800px) {
  .landing-header section.hero .congress-name h2 {
    font-size:calc(2em + 1vw);
  }
  .landing-header section.congress-info {
    padding-bottom:0;
    div.logo-container img.congress-logo {
      max-height:100px;
    }
    h1#congress-claim {
      line-height:1em;
    }
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .landing-header section.congress-info .card-container .card {
    margin:0 2vw;
  }
}

</style>
