<template>
  <div>
    <section class="info-housing">
      <transition name="fade-in-bottom-up" appear>
        <div v-show="showInfoHousing" class="content-wrapper">
          <h2>{{ site.info_housing_title }}</h2>
          <div class="topics-container">
            <div class="topic">
              <img src="../../assets/info-housing/ic_housing_bureau_portfolio.svg" alt>
              <p>{{ site.info_housing_first_text }}</p>
            </div>
            <div class="topic">
              <img src="../../assets/info-housing/ic_housing_bureau_support.svg" alt>
              <p>{{ site.info_housing_second_text }}</p>
            </div>
            <div class="topic">
              <img src="../../assets/info-housing/ic_housing_bureau_international.svg" alt>
              <p>{{ site.info_housing_third_text }}</p>
            </div>
          </div>
          <div class="topics-container-mobile">

            <vueper-slides style="width: 100%" :arrows="false" bullets-outside>

              <vueper-slide>
                <template #content>
                  <div class="topic">
                    <img src="@/assets/info-housing/ic_housing_bureau_portfolio.svg" alt>
                    <p>{{ site.info_housing_first_text }}</p>
                  </div>
                </template>
              </vueper-slide>
              <vueper-slide>
                <template #content>

                  <div class="topic">
                    <img src="@/assets/info-housing/ic_housing_bureau_support.svg" alt>
                    <p>{{ site.info_housing_second_text }}</p>
                  </div>
                </template>
              </vueper-slide>
              <vueper-slide>
                <template #content>

                  <div class="topic">
                    <img src="@/assets/info-housing/ic_housing_bureau_international.svg" alt>
                    <p>{{ site.info_housing_third_text }}</p>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import {mapGetters} from 'vuex'

export default {
  name: 'InfoHousing',
  props: {
    showInfoHousing: Boolean
  },
  computed: {
    ...mapGetters(['site'])
  },
  components: {
    VueperSlides,
    VueperSlide
  }
}
</script>

<style>
  .vueperslides__parallax-wrapper {
    padding-bottom: 38% !important;
  }
  @media (max-width: 768px) {
    .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, 
    .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
      bottom:75px !important;
      box-shadow:none !important;
    }
    .vueperslides__parallax-wrapper {
      padding-top:0 !important;
    }
    
  }
  @media (min-width: 500px) and (max-width: 768px) {
    .vueperslides__bullets {bottom:75px;}
  }
</style>

<style lang="scss" scoped>
section.info-housing {
  height: 60vh;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/info-housing/event-1597531.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;

    h2 {
      font-size: 62px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
    }

    .topics-container-mobile {
      display: none;
    }

    .topics-container {
      display: flex;
      flex-direction: row;
      min-width: 80%;
      justify-content: space-between;

      .topic {
        p {
          font-weight: 600;
          font-size: 25px;
          color: #ffffff;
          text-align: center;
          line-height: 36px;
          width: 320px;
        }

        img {
          height: 100px;
        }
      }
    }
  }
}

.contact-container {
  height: 220px;
  color: #6e6e6e;
  display: flex;

  .content-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .contact-title {
    font-weight: 600;
    font-size: 16px;
    color: $primary-black;
  }

  .contact-topic {
    height: 160px;
    display: inline-block;

    img.logo {
      width: 140px;
    }
  }

  .contact-topic.social-media {
    text-align: left;
  }

  // Social Media Column
  .social-media {
    width: 214px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .icons-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .icons-container div {
    height: 18px;
  }

  // Contact Us Column
  .contact-us {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    color: #6e6e6e;
    letter-spacing: 0;
    line-height: 22px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 91px;

    .icons-container {
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        display: inline-block;
        margin: 0;
        padding-left: 14px;
      }

      p.email:hover {
        text-decoration: underline;
      }
    }
  }

  // Extra Information
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    p {
      font-family: "Source Sans Pro", sans-serif;
      font-weight: normal;
      // display: inline-block;
      margin: 15px 0;
    }

    > p {
      margin-top: 0;
    }

    img {
      padding-right: 10px;
    }

    .info-content {
      p:last-child {
        margin-bottom: 0;
      }

      p::before {
        content: var(--icon-circular-check);
        margin-right: 5px;
      }
    }
  }
}

@media (max-height: 900px) {
  section.info-housing {
    height: 45vh;
  }
  body section.info-housing .content-wrapper .topics-container .topic p {
    font-size: 18px;
    line-height: 20px;
  }
  body section.info-housing .content-wrapper h2 {
    font-size: 35px;
    margin-top: 10px;
  }

  body section.info-housing .content-wrapper .topics-container .topic img {
    height: 75px;
  }
}

@media (max-width: 768px) {
  section.info-housing {
    margin: 0;
    min-height: 430px;

    .content-wrapper {
      display: block;
      padding-top: 80px;

      h2 {
        font-size: 25px;
      }

      .topics-container {
        display: none;
      }

      .topics-container-mobile {
        display: flex;
        color: white;
        min-height: 55%;
        font-size: 15px;
        font-weight: 600;
        flex-direction: row;
        min-width: 80%;
        justify-content: space-between;

        section {
          margin: 0;

          img {
            height: 100px;
          }
        }

        .topic {
          text-align: center;

          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .contact-container {
    flex-direction: column;
    padding-top: 30px;
    height: 100%;

    .contact-topic {
      height: 100%;
    }

    .content-wrapper {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      .contact-topic {
        height: 100%;
      }

      .contact-topic.info {
        padding-top: 30px;
        margin-bottom: 20px;
      }

      .contact-topic.contact-us {
        p {
          margin-bottom: 10px;
        }

        .contact-content {
          height: auto;

          .icons-container {
            margin-bottom: 10px;
          }
        }
      }
    }

    .social-media {
      width: 100%;

      .icons-container {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        .contact-title {
          margin-bottom: 16px;
        }
      }

      .icons-container > div {
        height: auto;
        display: flex;
        width: 80vw;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom: 10px;

        div {
          flex-basis: 25%;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  section.info-housing .content-wrapper .topics-container .topic p {
    width: auto;
  }
  section.info-housing {
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-height: 900px) {
  section.info-housing {
    .content-wrapper {
      h2 {
        font-size: 50px;
      }

      .topics-container {
        .topic {
          p {
            font-size: 22px;
            line-height: 28px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .fade-in-bottom-up-enter-active {
    opacity: 0;
    animation: fade-in-bottom-up-in 1s forwards;
    animation-delay: 0.4s;
  }

  @keyframes fade-in-bottom-up-in {
    from {
      transform: translateY(300px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

</style>
