<template>
  <div>
    <section class="recommended-hotels">
      <transition-group name="show-list" tag="ul">
        <li
          v-for="hotel in getHotelsToShow(hotels)"
          :key="hotel.id"
          :style="setBackground(hotel)"
          @click="goToBooking(hotel)"
        >
          <hotel-card
            :hotel="hotel"
          ></hotel-card>
        </li>
      </transition-group>
    </section>
  </div>
</template>

<script>
import HotelCard from '@/components/landing-page/HotelCard.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'RecommmendedHotels',
  components: {
    HotelCard
  },
  computed: {
    ...mapGetters({site: 'site', hotels: 'recommendedHotels'})
  },
  methods: {
    setBackground (hotel) {
      return {
        background: 'linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ) , url(' + hotel.images[0] + ') no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'
      }
    },

    goToBooking (hotel) {
      this.$router.push({
        name: 'hotel-detail-page',
        params: {
          id: hotel.id
        }
      })
    },
    getHotelsToShow(hotels) {
      const hotelsLength = Object.keys(hotels).length
      switch (true) {
        case (hotelsLength >= 9):
          return hotels.slice(0, 9)
        case (hotelsLength < 9 && hotelsLength > 6):
          return hotels.slice(0, 6)
        default:
          return hotels
      }
    }
  }
}
</script>

<style lang="scss" scoped>

section.recommended-hotels {
  //height: 100vh;
  height:calc(96vh - 60px);
  margin-top:80px;

  ul {
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    margin-bottom: 0;
    // display: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 49.75%;
      margin-bottom: 1%;
      position: relative;
      color: #fff;
      background: #333;
      transition: box-shadow 0.5s linear;
      &:hover {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.5);
        cursor: pointer;
      }
    }

    // Cool flex allowing for 2x2 and 3x2 configurations
    li:nth-last-child(n+5):first-child,
    li:nth-last-child(n+5):first-child ~ *,
    li:nth-last-child(n+6):first-child,
    li:nth-last-child(n+6):first-child ~ * {
      flex-basis: 32.5%;
    }

    li:nth-child(5):last-child {
      margin-right: auto;
      margin-left: .5%;
    }
  }
}

@media (max-width: 768px) {
    section.recommended-hotels{
      min-height:calc(96vh - 60px);
      height:auto !important;
      ul {
        flex-direction: row;

        li {
          margin-bottom: 10px;
          min-height: 250px;
        }

        li:nth-last-child(n+5):first-child,
        li:nth-last-child(n+5):first-child ~ *,
        li:nth-last-child(n+6):first-child,
        li:nth-last-child(n+6):first-child ~ *  {
          flex-basis: 100%;
        }
      }

    }
  }

@media (min-width: 768px) {

  $total-items: 6;

  @for $i from 1 through $total-items {
    li:nth-child(#{$i}) {
      opacity: 0;
      animation: fadeInFromBottom 0.3s ease-in 0.1s * $i forwards;
    }

    @keyframes fadeInFromBottom {
      0% {
        opacity: 0;
        transform: translateY(100 * $i + px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

  }
}

// Keyframe animation

</style>
