<template>
  <article class="hotel-card">
    <div class="card-text">
      <span>
        <img v-for="(star, i) in hotel.stars" :key="`stars-hotel-${hotel.name}-${i}`" src="../../assets/ic_home_star_white_single.svg" alt="">
      </span>
      <h3 v-bind:style="adaptFontSizeIfHotelNameIsLarge(hotel.name)">{{hotel.name}}</h3>
      <h4 v-if="dataHotelsIsReady">{{formatPrice(hotel.average_day_price)}} €</h4>
    </div>
    <div class="congress-venue">
      <img src="../../assets/ic_home_location_white.svg" alt="">
      <p class="congress-venue"> {{hotel.km_to_congress}} {{ $t('kmToCongress') }}</p>
    </div>
    <div v-if="hasCategories" class="category-container">
      <img :src="categorie.icon" alt="">
      <p class="category">{{categorie.name}}</p>
    </div>
  </article>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'HotelCard',
  data: () => {
    return {
      hasCategories: false,
      categorie: {
        key: '',
        name: '',
        icon: ''
      }
    }
  },
  props: {
    hotel: Object,
    congressLat: Number,
    congressLng: Number
  },
  methods: {
    // @todo, setup a mixin to format prices
    formatPrice (price) {
      const roundedPrice = Math.ceil(price)
      return `${roundedPrice},00`
    },
    adaptFontSizeIfHotelNameIsLarge (hotelName) {
      if (hotelName.length >= 35) {
        return { fontSize: '22px' }
      } else if (hotelName.length >= 22) {
        return { fontSize: '35px' }
      }
    }
  },
  computed: {
    ...mapGetters(["dataHotelsIsReady"]),
  }
}
</script>

<style lang="scss" scoped>

.hotel-card:hover {
  cursor: pointer;
}

.hotel-card {
  font-weight: bold;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 80% 20%;
  grid-template-areas:
    "basicInfo"
    "congressVenue";

  .card-text {
    grid-area: basicInfo;
    align-self: center;
    margin-top: 35px;

    span {

      img {
        height: 15px;
      }
    }

    h3 {
      text-decoration: none;

      font-size: 22px;
      margin: 10px 0;
      // line-height: 15px;
    }

    h4 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: bold;
      font-size: 16px;
      margin: 10px 0;
    }
  }

  .congress-venue {
    grid-area: congressVenue;
    display: flex;
    justify-content: center;
    font-size: 14px;

    img {
      height: 17px;
    }
  }

  .category-container {
    position: absolute;
    height: 30px;
    top: -1px;
    right: -1px;
    padding: 0 5px;
    border-bottom-left-radius: 18px;
    background-color: white;
    color: $secondary-black;
    display: flex;
    font-size: 13px;

    & img {
      padding: 5px;
    }

    & p {
      line-height: 30px;
      // margin: 10px 0;
    }
  }
}

@media (min-width: 768px) {
    .hotel-card{
      grid-template-rows: 85% 15%;

      .card-text {
        span {
          img {
            height: 24px;
          }
        }

        h3 {
          font-size: 42px;
          line-height: initial;
        }

        h4 {
          font-size: 30px;
        }
      }

      .congress-venue {
        font-size: 18px;

        img {
          height: 24px;
        }
      }

      .category-container {
        height: 40px;
        font-size: 16px;

        p {
          line-height: 40px;
        }
      }

    }
  }

  @media screen and (max-height: 800px) {
  .card-text {
    margin-top:0 !important;
    h3 {
      font-size:22px !important;
      margin:1vh 0 !important;
    }
    h4 {
      font-size:22px !important;
      margin:1vh 0 !important;
    }
  }
  .congress-venue {
    position:relative;
    bottom:1px;
    img {width:15px;}
  }
}

</style>
