<template>
  <spinner :visible="spinnerVisible"></spinner>
  <div class="home">
    <div>
      <navbar :showNavbar="showNavbar"></navbar>
      <full-page :options="fullPageOptions" id="fullpage">
        <landing-header
          class="section"
        ></landing-header>
        <div class="section">
          <recommended-hotels
            :showRecommendedHotels="showRecommendedHotels"
            :hotels="recommendedHotels"
          ></recommended-hotels>
        </div>
        <div class="footer-container section">
          <info-housing :showInfoHousing="showInfoHousing"></info-housing>
          <contact-footer
            :showFooter="showFooter"
            :congressEmail="site.email"
          ></contact-footer>
          <legal-footer :showFooter="showFooter"></legal-footer>
        </div>
      </full-page>
    </div>
    <div class="spinner-container" v-if="!dataIsReady">
      <circle2></circle2>
    </div>
  </div>
</template>

<script>
import FullPage from '@/components/FullPage';
import LandingHeader from "@/components/landing-page/LandingHeader";
import RecommendedHotels from "@/components/landing-page/RecommendedHotels";
import ContactFooter from "@/components/landing-page/ContactFooter";
import InfoHousing from "@/components/landing-page/InfoHousing";
import LegalFooter from "@/components/landing-page/LegalFooter";
import Navbar from "@/components/Navbar";
import Spinner from "@/components/Spinner";
import { Circle2 } from "vue-loading-spinner";
import { mapGetters, mapState } from "vuex";

export default {
  name: "home",
  components: {
    FullPage,
    LandingHeader,
    RecommendedHotels,
    ContactFooter,
    InfoHousing,
    LegalFooter,
    Navbar,
    Circle2,
    Spinner,
  },
  data: function () {
    return {
      fullPageOptions: {
        licenseKey: "03760796-FE27410E-BDB3255A-A6E19771",
        sectionsColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        onLeave: this.onLeave,
        fitToSection: false,
        responsiveWidth: 768,
      },
      showNavbar: false,
      showRecommendedHotels: false,
      showInfoHousing: false,
      showFooter: false,
      spinnerVisible: false,
    };
  },
  methods: {
    onLeave(origin, destination, _direction) {
      if (destination.index) { 
        this.showNavbar = true
       } else { 
         this.showNavbar = false
       }
      if (destination.index === 1) {
        this.showRecommendedHotels = true;
        origin.item.classList.add("fade-out-section");
      } else if (destination.index === 2) {
        this.showInfoHousing = true;
        this.showFooter = true;
        origin.item.classList.add("fade-out-section");
      } else if (destination.index === 3) {
        this.showFooter = true;
      }
    },
  },
  computed: {
    ...mapGetters(["dataIsReady", "recommendedHotels", "site", "dataHotelsIsReady"]),
  },
  watch: {
    dataHotelsIsReady(newValue) {
      if(newValue) {
        this.spinnerVisible = false
      }
    }
  },
  mounted() {
    if(!this.dataHotelsIsReady) {
      this.spinnerVisible = true
    }
    if (window.screen.width <= 768) {
      this.showFooter = true;
      this.showInfoHousing = true;
      this.showRecommendedHotels = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/color-mixin.scss";

.spinner-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.spinner {
  border-color: white var(--primary-color) var(--primary-color) !important;
  width: 80px !important;
  height: 80px !important;
  border-width: 5px !important;
}

.footer-container {
  margin: 0;
}

// Animations
@media (min-width: 768px) {
  .fade-out-section {
    animation: fade-out-section 0.5s;
  }

  @keyframes fade-out-section {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
